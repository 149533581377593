const toggleDropdownInputActive: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.dropdownInput');

    if (parent.classList.contains('active')) {
      window.removeEventListener('keydown', handleDropDownSearch);
    } else {
      window.addEventListener('keydown', handleDropDownSearch);
    }

    parent.classList.toggle('active');
  } catch (error) {
    console.error(error);
  }
};

var recentKeyPress: string = '';
const handleDropDownSearch: (event: any) => void = event => {
  const activeDropDown: any = document.getElementsByClassName('dropdownInput active')[0];

  if (!activeDropDown) return;

  const optionContainer: any = activeDropDown?.getElementsByClassName('options')[0];
  const options: any[] = activeDropDown?.getElementsByClassName('option');
  const pressedKey: string = event.key;

  recentKeyPress += pressedKey;

  for (var a = 0; a < options.length; a++) {
    const dataValue: string = options[a]?.dataset?.value || '';

    if (dataValue.toLowerCase().indexOf(recentKeyPress.toLowerCase()) === 0) {
      optionContainer.scrollTop = options[a].offsetTop;
      break;
    }
  }

  setTimeout(() => {
    recentKeyPress = '';
  }, 1000);
};

const clearDropdownInputError: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.dropdownInput');

    parent.classList.remove('invalid');
  } catch (error) {
    console.error(error);
  }
};

const setDropdownInputError: (dropdownInputID: string) => void = dropdownInputID => {
  try {
    const dropdownInput: any = document.getElementById(dropdownInputID);
    const parent: any = dropdownInput?.closest('.dropdownInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const validateDropdownInput: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.dropdownInput');
    const valueInput: any = parent?.getElementsByTagName('input')[0];
    const value: string = valueInput?.value || '';
    const isRequired: boolean = parent?.classList.contains('required');

    parent.classList.remove('invalid');

    if (isRequired) {
      const isValidDropdownValue: boolean = getIsValidDropdownValue(value);

      if (!isValidDropdownValue) parent.classList.add('invalid');

      return;
    }
  } catch (error) {
    console.error(error);
  }
};

const getIsValidDropdownValue: (value: string) => boolean = value => {
  try {
    if (value === '') return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const handleDropdownOptionSelect: (event: any) => void = event => {
  try {
    const option: any = event?.currentTarget || event?.target;
    const optionData: string = option?.dataset?.value || '';
    const optionText: string = option?.innerText || '';
    const parent: any = option?.closest('.dropdownInput') || null;
    const dropdownValueInput: any = parent?.getElementsByTagName('input')[0];
    const dropdownValueText: any = parent?.getElementsByClassName('currentValue')[0];

    dropdownValueInput.value = optionData;
    dropdownValueText.innerText = optionText;
    parent.classList.remove('active');
    parent.classList.remove('initial');
    parent.classList.remove('invalid');

    window.removeEventListener('keydown', handleDropDownSearch);
  } catch (error) {
    console.error(error);
  }
};

const handleDropdownBackgroundClick: (event: any) => void = event => {
  toggleDropdownInputActive(event);
  validateDropdownInput(event);
};

const relocateDropdownInputValueByIdToNewInputByIdAndReturnSuccess: (
  dropdownInputID: string,
  newInputID: string
) => boolean = (dropdownInputID, newInputID) => {
  try {
    const dropdownInput: any = document.getElementById(dropdownInputID);
    const newDropdownInput: any = document.getElementById(newInputID);
    const dropdownInputValue: string = dropdownInput?.value || '';

    newDropdownInput.value = dropdownInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
